import React, {useState} from "react";
import styled from "styled-components";
import { Landing, Result_block } from "components";
import Flippy, { FrontSide, BackSide } from 'react-flippy';
import { CA, defaultCA, isSupportedChainId } from "../../constants.js";
import { FLIPGAMEABI } from "abis/FlipGameABI";
import { useContractFunction, useEthers } from "@usedapp/core";
import { Contract } from '@ethersproject/contracts';

const Home = ({ className, darkMode, setDarkMode, sounds, setShowNetworkSwitcherModal}) => {
  const { active, chainId } = useEthers();
  const [isFlipped, setIsFlipped] = useState(false);
  const gameContract = new Contract((active && isSupportedChainId(chainId)) ? CA[chainId?.toString()] : defaultCA, FLIPGAMEABI);
  const flipFunction = useContractFunction(
    gameContract,
    'flipCoin',
    { transactionName: 'Coin Flip', chainId: chainId ?? 1285 }
  );

  return (
    /* home page html */
    <Flippy flipOnClick={false} isFlipped={isFlipped}>
      <FrontSide>
        <div className={className}>
          <Landing darkMode={darkMode} setDarkMode={setDarkMode} isFlipped={isFlipped} setIsFlipped={setIsFlipped} sounds={sounds} flipFunction={flipFunction} setShowNetworkSwitcherModal={setShowNetworkSwitcherModal}/>
        </div>
      </FrontSide>
      <BackSide>
        <Result_block isFlipped={isFlipped} setIsFlipped={setIsFlipped} darkMode={darkMode} sounds={sounds} flipFunction={flipFunction}/>
      </BackSide>
    </Flippy>

  );
};

/* home page styles */
export default styled(Home)``;
