import { ChainId } from "@usedapp/core";

// Chain ID -> contract address map
export const CA = {
    "1285": "0x7d5de78E0f2b73C3D6324D65A1a765dF59424713",
    "1284": "0x7d5de78E0f2b73C3D6324D65A1a765dF59424713",
    "43113": "0x7d5de78E0f2b73C3D6324D65A1a765dF59424713",
    "43114": "0x7d5de78E0f2b73C3D6324D65A1a765dF59424713",
    "5": "0x7d5de78E0f2b73C3D6324D65A1a765dF59424713"
}

export const defaultCA = "0x7d5de78E0f2b73C3D6324D65A1a765dF59424713";

export const defaultChain = ChainId.Moonriver; // Update to MOVR for release
export const supportedChains = [ChainId.Moonriver, ChainId.Avalanche];


export function isSupportedChainId(chainId) {
  return supportedChains.findIndex((value, index, arr) => value === chainId) !== -1;
}

export const apiUrl = 'https://moonflip-ka9d7.ondigitalocean.app/';
export const apiProcessTxn = apiUrl + 'process_txn/';

export const losingText = [
    "Rekt!",
    "RIP.",
    "Oops. You lose.",
    "Nice loss!",
    "You rugged yourself.",
    "Better luck next time.",
    "Try Again"
  ]
  
  export const winningText = [
    "Congratulations! You mooned once!",
    "Congratulations! You mooned TWO times! Nice!",
    "Congratulations! You mooned THREE times! Sweet!",
    "Congratulations! You mooned FOUR times! Wow!",
    "Congratulations! You mooned FIVE times! Chad!",
    "Congratulations! You mooned SIX times! Giga Chad!",
    "Congratulations! You mooned SEVEN times! BEAST!",
    "Congratulations! You mooned EIGHT times! LORD!",
    "9 Times..... Really???",
    "10 times? Lol you are hacking.",
    "11 times.. You are rugging MoonFlip",
    "A Dozen? This is quite damn near impossible.",
    "Please DM our devs with a screenshot of your 13x win. Fucking hacker.",
    "14 times. God.",
    "15x. enough said.",
    "Wizard"
  ];

  export const winningSounds = [
    '/assets/sounds/win1.mp3',
    '/assets/sounds/win2.mp3',
    '/assets/sounds/win3.mp3'
  ];

  export const NUM_BET_AMOUNTS = 6;