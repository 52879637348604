import React, {useState, useEffect} from "react";
import { ethers, utils } from "ethers";
import { CA, defaultChain, isSupportedChainId, defaultCA } from "../../constants.js";
import { FLIPGAMEABI } from "abis/FlipGameABI";
import { ChainId, useEthers, useBlockNumber } from "@usedapp/core";
import {BigNumber} from "@ethersproject/bignumber";
import {Container, Row, Col} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { shortenIfAddress } from "@usedapp/core";
import GridLoader from 'react-spinners/GridLoader';


const RecentPlays = ({darkMode, showing}) => {

    const [plays, setPlays] = useState([]);
    const { active, library, account, chainId } = useEthers();
    const gameContract = new ethers.Contract((active && isSupportedChainId(chainId)) ? CA[chainId?.toString()] : defaultCA, FLIPGAMEABI, library);
    const latestBlock = useBlockNumber();
    const filterFrom = gameContract.filters.Outcome(null, null, null, null);

    async function getPlays() {
        try {
            const plays = await gameContract.queryFilter(filterFrom, latestBlock - 500, latestBlock);
            let temp = [];
            plays.slice(-100).forEach((play, index) => { // only show last 10
                const round = {};
                if (play?.args?.game === 1) { // only read coinflips
                    round.participant = play?.args?.participant;
                    round.won = play?.args?.won;
                    round.value = play?.args?.value;
                }
                temp.push(round);
            });
    
            setPlays(temp);
        } catch (error) {
            console.log("error getting recent games:", error)
        }
    }

    useEffect(() => {
        try {
            if (active && (defaultChain === chainId)) {
                const interval = setInterval(() => {
                    getPlays();
                  }, 7000);
                  return () => clearInterval(interval);
            }
        } catch (error) {
            console.log(error);
        }

    }, [account, library, chainId, showing])
    

    return (
        <Container>
            <button className="modal-close-button center-text mb-20" onClick={() => getPlays()}>Refresh</button>
            <Row className="center-row w-100">{plays.length === 0 && <GridLoader size={15} margin={2} color={"white"}/>}</Row>
            {plays.length > 0 && <Row className="row table-headers">
                <Col className="center-text cruiser-font">Player</Col>
                <Col className="center-text cruiser-font">Status</Col>
                <Col className="center-text cruiser-font">Amount</Col>
            </Row>}
            <div className="scrollable">{plays.map((i, index) => (
                    <Row className="row">
                        <Col className="col center-text">
                            {shortenIfAddress(i?.participant)}
                        </Col>
                        <Col className="col center-text">
                            {i?.won ? "Won" : "Lost"}
                        </Col>
                        <Col className="col center-text">
                            {Number(utils.formatEther(BigNumber.from(i?.value ?? 0))).toFixed(2)}
                        </Col>
                    </Row>
            ))}</div>
        </Container>
    );
}

export default RecentPlays;