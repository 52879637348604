import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ChainId, useEthers, useContractCalls, useContractCall } from "@usedapp/core";
import useSound from 'use-sound';
import FlippableCoin from "components/common/coin.js";
import { supportedChains, NUM_BET_AMOUNTS, CA, isSupportedChainId, defaultCA } from "../../constants.js";
import { chainData } from "components/common/helpers.js";
import { FLIPGAMEABI } from "abis/FlipGameABI.js";
import { Interface } from '@ethersproject/abi';
import { BigNumber } from '@ethersproject/bignumber';
import {utils} from 'ethers';

const Landing = ({ className, darkMode, setDarkMode, isFlipped, setIsFlipped, sounds, flipFunction, setShowNetworkSwitcherModal }) => {

  const { account, library, active, activate, activateBrowserWallet, chainId } = useEthers();
  const [guess, setGuess] = useState(-1); // 0 = heads, 1 = tails
  const [value, setValue] = useState("0");
  const [betIndex, setBetIndex] = useState(0);
  const [validInput, setValidInput] = useState(false);
  const [buttonText, setButtonText] = useState("Connect");
  const [coinSpin, setCoinSpin] = useState(false);
  const [playFlipSound] = useSound('/assets/sounds/flip.mp3');
  const gameInterface = new Interface(FLIPGAMEABI);
  const calls = [];
  if (isSupportedChainId(chainId)) {
    for (let i = 0; i < NUM_BET_AMOUNTS; i++) {
      calls.push(
        {abi: gameInterface, address: (active && isSupportedChainId(chainId)) ? CA[chainId?.toString()] : defaultCA, method: "betAmounts", args: [BigNumber.from(i)]}
      );
    }
    calls.push({abi: gameInterface, address: (active && isSupportedChainId(chainId)) ? CA[chainId?.toString()] : defaultCA, method: "paused", args: []});
  }

  const bets = useContractCalls(calls, {chainId: chainId ?? ChainId.Moonriver});

  let betAmounts = [];
  let betValues = [];

  for (let i = 0; i < NUM_BET_AMOUNTS; i++) {
    const bigNumberBet = BigNumber.from(bets?.[i]?.[0] ?? 0);
    betAmounts.push(utils.formatEther(bigNumberBet?.toString()));
    betValues.push((bigNumberBet.mul(10444).div(10000)).toString());
  }

  async function handleBigButton() {
    if (!account) {
      activateBrowserWallet();
    } else if (account && !supportedChains.includes(chainId)) {
      setShowNetworkSwitcherModal(true);
    } else if (validInput) {
      if (sounds) {
        playFlipSound();
      }
      if (!["Mining", "Waiting", "Success", "PendingSignature"].includes(flipFunction.state.status)) {
        flipGame();
      }
    } else {
      setButtonText("CHOOSE")
    }
  }

  const flipGame = () => {
    try {

      library.getGasPrice().then(gasPrice => {

        flipFunction.send(guess, betIndex, {
          value: value,
          gasLimit: 250_000,
          gasPrice: gasPrice
        })
      })

    } catch (error) {
      console.log(error ?? "");
    }
  };

  useEffect(() => {
    setValidInput(value !== "0" && guess !== -1);
  }, [value, guess])

  useEffect(() => {
    if (account && supportedChains.includes(chainId)) {
      if (validInput) {
        setButtonText("Flip Now");
      } else if (betAmounts[0] === "0.0") {
        setButtonText("Loading");
      } else if (bets?.[6]?.[0] ?? false) {
        setButtonText("Paused");
      } else {
        setButtonText("CHOOSE");
      }

      if (['Exception', 'Fail'].includes(flipFunction.state.status)) {
        console.log(flipFunction.state.errorMessage);
      }

      if (flipFunction.state.status !== undefined) {
        // setFlipTxState(flipFunction);
        if (flipFunction.state.status === 'Mining') {
          setButtonText("Submitting");
          setCoinSpin(true);
        } else if (flipFunction.state.status === "PendingSignature") {
          setButtonText("Waiting");
          setCoinSpin(true);
        } else if (flipFunction.state.status === 'Success') {
          setButtonText("Processing");
          setCoinSpin(false);
          setIsFlipped(true);
        } else if (['Exception', 'Fail'].includes(flipFunction.state.status) && flipFunction.state.errorMessage?.includes('insufficient funds')) {
          setButtonText("Too Broke");
          setCoinSpin(false);
        } else if (['Exception', 'Fail'].includes(flipFunction.state.status) && flipFunction.state.errorMessage?.includes('User denied')) {
          setButtonText("TX Denied");
          setCoinSpin(false);
        } else if (['Exception', 'Fail'].includes(flipFunction.state.status) && flipFunction.state.errorMessage?.includes('not enough liquidity')) {
          setButtonText("No Liq");
          setCoinSpin(false);
        } else {
          if (validInput) {
            setButtonText("Flip Now");
          } else if (betAmounts[0] === "0.0") {
            setButtonText("Loading");
          } else if (bets?.[6]?.[0] ?? false) {
            setButtonText("Paused");
          } else {
            setButtonText("CHOOSE");
          }
          setCoinSpin(false);
        }
      }

    } else if (account) {
      setButtonText("Wrong N/W")
    } else {
      setButtonText("Connect");
    }
  }, [flipFunction, account, chainId, validInput, bets]);

  return (
    /* visual block html */
    <div className={`${className}`}>
      <div className="block-selection">
        <strong className="logo">
          <Link to={"/"}>
            <img src="/assets/images/banner_no_bg.png" alt="image description" />
          </Link>
        </strong>
        <div className={`box-area ${!darkMode ? 'box-area-light' : ''}`}>
          <div className={`bars-holder ${isFlipped && 'hide-mobile'}`}>
            <span className="bars">
              <span className="bar"></span>
              <span className="bar"></span>
              <span className="bar"></span>
            </span>
            <span className="bars bottom">
              <span className="bar"></span>
              <span className="bar"></span>
              <span className="bar"></span>
            </span>
            <strong className={`coin-holder ${!coinSpin && 'hide'}`}>
              {guess === 0 && <img className={`${coinSpin ? "img-spin" : ""}`} src="/assets/images/coinfront.png" alt="image description" />}
              {guess === 1 && <img className={`${coinSpin ? "img-spin" : ""}`} src="/assets/images/coinback.png" alt="image description" />}
            </strong>
            {!coinSpin && <FlippableCoin flipState={guess} setFlipState={setGuess} />}
            {(account && buttonText !== "Submitting" && buttonText !== "Waiting" && buttonText !== "Wrong N/W" && betAmounts[0] !== "0.0" && buttonText !== "Paused") && <div className="coins-type">
              <ul className="list-items">
                <li>
                  <label className="fake-radio">
                    <input type="radio" name="coin" defaultChecked={guess === 0} />
                    <div className="btn-radio" onClick={() => setGuess(0)}>
                      <span className={`text-box ${!darkMode && 'text-box-light'}`}>Heads</span>
                    </div>
                  </label>
                </li>
                <li>or</li>
                <li>
                  <label className="fake-radio">
                    <input type="radio" name="coin" defaultChecked={guess === 1} />
                    <div className="btn-radio" onClick={() => setGuess(1)}>
                      <span className={`text-box ${!darkMode && 'text-box-light'}`}>Tails</span>
                    </div>
                  </label>
                </li>
              </ul>
              <ul className="list-more-options">
                {["w-33", "w-33", "w-33", "w-50", "w-50", "w-100"].map((width, index) => (
                  <li className={width}>
                    <label className="fake-radio">
                      <input type="radio" name="price" defaultChecked={(value === betValues[index]) && value !== "0"} />
                      <div className="btn-radio" onClick={() => {setValue(betValues[index]); setBetIndex(index)}}>
                        <span className={`text-box ${!darkMode && 'text-box-light'}`}>
                          <span className="price">{betAmounts[index]}</span>
                          <span className="text">{chainData(chainId)}</span>
                        </span>
                      </div>
                    </label>
                  </li>
                ))}
              </ul>
            </div>}
            <Link to="#" className="hexa-button" onClick={handleBigButton}>
              <span className="hexa-inner left-arrow"> </span>
              <span className="hexa-inner right-arrow"> </span>
              <span className="hexa-button-inner1">
                <span className="hexa-inner1 left-arrow"> </span>
                <span className="hexa-inner1 right-arrow"> </span>
                <span className="hexa-button-inner2">
                  <span className="hexa-inner2 left-arrow"> </span>
                  <span className="hexa-inner2 right-arrow"> </span>
                  <span className="hexa-button-inner3">
                    <span className="hexa-inner3 left-arrow"> </span>
                    <span className="hexa-inner3 right-arrow"> </span>
                  </span>
                </span>
              </span>
              <span className="text">{buttonText}</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

/* text block styles */
export default styled(Landing)``;
